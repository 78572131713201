import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DataService } from "src/app/data.service";
import { CreateService } from "../../create.service";
import { MatTabChangeEvent } from "@angular/material";
import { params } from "src/app/pages/params";
import { urls } from "src/app/pages/urls";
import { Utility } from "src/app/pages/utility";
import { MatDialog } from '@angular/material';
import { MatTableDataSource} from "@angular/material";

@Component({
  selector: "app-attribute-msn-mapping",
  templateUrl: "./attribute-msn-mapping.component.html",
  styleUrls: ["./attribute-msn-mapping.component.scss"],
})
export class AttributeMsnMappingComponent implements OnInit {
  selectedGroup: any;
  attributes = [];
  selectedAttributes = [];
  selectedMsn = [];
  selectedTab: any = "Attributes";
  allAttributes = [];
  countryCode: any;
  userEmail: string;
  file: any;
  fileName: any;
  mappedSelectedAttributes: any = [];
  isGroupSelected: boolean = false;
  allMSNs: any =[];
  selectedMSNList= [];
  mappedSelectedMSNs: any = [];
  msnList: any = [];
  isActiveMsnList: boolean = false;
  tabIndex: number = 0;
  failureMSNs = [];
  displayedColumns: string[] = [
    "idProduct",
    "categoryCode",
    "brandName",
    "failureReason"
  ];
  groupedFor:string='';
  public dataSource = new MatTableDataSource(this.failureMSNs);
  @ViewChild('confirmDialog1') confirmDialog1: TemplateRef<any>;
  MsnSuccessMsg: string;
  alreadyMappedAttributes: any = [];
  MsnSuccessDemapMsg: string;

  constructor(private createService: CreateService, private service: DataService,  public dialog: MatDialog) {}

  ngOnInit() {
    this.countryCode = this.service.getSelectedCountryCode();
    this.userEmail = this.service.getEmail();

    this.createService.selectedGroupUpdate.subscribe((lang) => {
      this.selectedAttributes = [];
      this.mappedSelectedAttributes = [];
      this.msnList = [];
      this.selectedMSNList =[];
      this.selectedGroup = this.createService.getSelectedGroup();
      this.mappedAttributesListData(this.selectedGroup.attributeDetails);
      this.isGroupSelected = true;
      this.getAttributesByCategory();
      this.getMSNList();
    });
    this.createService.removeGroupUpdated.subscribe((lang) => {
      this.selectedAttributes = [];
      this.attributes =[];
      this.mappedSelectedAttributes = [];
      this.msnList =  [];
      this.selectedMSNList = [];
      this.selectedGroup = null;
      this.isGroupSelected = false;
    });
    this.createService.updateListedAttribute.subscribe((length) => {
        if(length.listedAttributeList && length.listedAttributeList > 0){
          this.mappedAttributesListData(length.attributeList);
          this.isActiveMsnList = true;
        }else{
          this.mappedAttributesListData(length.attributeList);
          this.selectedTab= "Attributes";
          this.tabIndex = 0;
          this.isActiveMsnList = false;
        }
    });
  }

  mappedAttributesListData(attributes){
    if(attributes != null){
      this.alreadyMappedAttributes = attributes.map(attr => attr.attributeId);
    }
  }

  getAttributesByCategory() {
    let url = params.old_prod_supplier + urls.OLD.getFetchTemplate;
    let body = {categoryCodeList: [this.selectedGroup.catalogGroupDetails.categoryId]};
    this.service.callRestful("POST", url, body, null, null, null).subscribe((resp) => {
      if (resp && resp.data !== null && resp.data !== {}) {
        this.attributes = resp["templateFetchRO"]["templates"];
          let attributesList = this.attributes.map((attr) => ({...attr,checked: false}));
          this.attributes = attributesList;
        }
        this.service.offSpinner();
      });
  }

  getMSNList(){
    this.service.onSpinner();
    let url = params.old_prod_supplier + urls.getMSNList;
    let body = {
      "categoryCode":this.selectedGroup.catalogGroupDetails.categoryId,
      "brandId":this.selectedGroup.catalogGroupDetails.brandId,
      "countryCode" : this.service.getSelectedCountryCode()
    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe((resp) => {
        if (resp && resp.data !== null && resp.data !== {}) {   
          this.msnList = resp["data"];
          let msnsList = this.msnList.map((msn) => ({...msn,checked: false}));
          this.msnList = msnsList;
        }
        this.service.offSpinner();
      });
  }

  selectAttibutes(attribute: any): void {
    let attr = {
      attributeId: attribute.attributeId,
      attributeName: attribute.attributeName,
    };
    if (this.selectedAttributes.length === 0) {
      this.selectedAttributes.push(attribute.attributeId);
      this.mappedSelectedAttributes.push(attr);
    } else if (this.selectedAttributes.includes(attribute.attributeId)) {
      let attr = this.selectedAttributes.filter(
        (item) => item !== attribute.attributeId
      );
      this.selectedAttributes = attr;
      let attrList = this.mappedSelectedAttributes.filter((item) => {
        item.attributeId !== attribute.attributeId;
      });
      this.mappedSelectedAttributes = attrList;
    } else {
      this.selectedAttributes.push(attribute.attributeId);
      this.mappedSelectedAttributes.push(attr);
    }
  }

  getAttributes(attribute): any {
    return this.selectedAttributes.includes(attribute) ? true : false;
  }

  mapSelectedAttributes() {
    let attributes = [...this.alreadyMappedAttributes,...this.selectedAttributes];
    const url = params.old_prod_supplier + urls.createGroup;
    let body = {
      groupId: this.selectedGroup.catalogGroupDetails.groupId,
      brandId :this.selectedGroup.catalogGroupDetails.brandId,
      attributeId: attributes,
      categoryId: this.selectedGroup.catalogGroupDetails.categoryId,
      countryCode: this.countryCode,
      userId : this.userEmail,
      priority: 1,
      groupedFor: this.selectedGroup.catalogGroupDetails.groupedFor.toUpperCase()
    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe(this.processResult.bind(this));
  }

  processResult(data: any) {
    this.service.offSpinner();
    if (data["status"]) {
      this.createService.setCreateAttrList(this.selectedAttributes,this.mappedSelectedAttributes);
      this.isActiveMsnList = true;
      this.selectedAttributes = [];
      this.mappedSelectedAttributes = [];
      this.service.showMessage("success", data["statusDescription"]);
    } else {
      this.service.showMessage("error", data["statusDescription"]);
    }
  }

  downloadTemplate() {
    let csvData = Utility.convertToCSV([],["product id", "parent", "map"]);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, "Bulk-MSN-Creation");
  }

  selectMSN(msn : any){
    if (this.selectedMSNList.length === 0) {
      this.selectedMSNList.push(msn.idProduct);
      this.mappedSelectedMSNs.push(msn);
    } else if (this.selectedMSNList.includes(msn.idProduct)) {
      let attr = this.selectedMSNList.filter((item) => item !== msn.idProduct);
      this.selectedMSNList = attr;
      let attrList = this.mappedSelectedMSNs.filter((item) => {item.attributeId !== msn.idProduct});
      this.mappedSelectedMSNs = attrList;
    } else {
      this.selectedMSNList.push(msn.idProduct);
      this.mappedSelectedMSNs.push(msn);
    }
  }

  resetCheckBox() {
    this.msnList.forEach(item => {
      const foundIndex = this.msnList.findIndex(x => x.value === item.value);
      item.checked = false;
      this.msnList[foundIndex] = item;
    });
   }

  mapSelectedMsn(){
    var obj = {};
    let body= {};
    this.selectedMSNList.forEach(each => {
    obj[each] = {'msn': each,'map': true,'parent': false}
    body = {"groupId" : this.selectedGroup.catalogGroupDetails.groupId,
      "productMap" : obj,
      "userId": this.userEmail,
      "countryCode": this.countryCode,
     }
    }); 
    const url = params.old_prod_supplier + urls.MapMSNList;
    this.service.callRestful('POST', url, body, null, null, null).subscribe( data => {
      this.service.offSpinner();
      if (data['status']) {
        //this.service.showMessage('success', data['statusDescription']);
        this.createService.setMSNList(this.mappedSelectedMSNs);
        this.resetCheckBox();
        this.selectedMSNList = [];
        this.mappedSelectedMSNs= [];
        let result = data['data'];
        this.processfailedMSN(result);
      } else {
        this.service.showMessage('error', data['statusDescription']);
      }
     }
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTab = tabChangeEvent.tab.textLabel;
    tabChangeEvent.tab.textLabel === "Attributes" ? this.tabIndex = 0 : this.tabIndex = 1;
  }

  processfailedMSN(result){
    this.failureMSNs = [];
    if(result['failedCount'] === 0){
      let failedMSNList =  result['mappedProductResponses'];
      this.processBulkMsnResponse(failedMSNList);
      this.openPopup(this.confirmDialog1);
    }else if(result['failedCount'] > 0){
      let failedMSNList =  result['mappedProductResponses'];
      this.failureMSNs = failedMSNList.filter(msn => msn.mappedStatus === false && msn.deMappedStatus === false);
      if(result['successCount'] > 0) {
        this.processBulkMsnResponse(failedMSNList);
      }
      this.openPopup(this.confirmDialog1);
    }
  }

  processBulkMsnResponse(failedMSNList){
    let successMsn = failedMSNList.filter(msn => msn.mappedStatus === true).map(msn =>  msn.idProduct);
    let successDemapMsn = failedMSNList.filter(msn => msn.deMappedStatus === true).map(msn =>  msn.idProduct);
    if(successMsn.length > 0){
    this.MsnSuccessMsg = successMsn.join(", ") + ' mapped successfully.';
    }
    if(successDemapMsn.length > 0){
      this.MsnSuccessDemapMsg = successDemapMsn.join(", ") + ' demapped successfully.';
    }
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    let flag = Utility.fileValidation(this.file);
    if (!flag) {
      this.file = null;
      this.service.showMessage("error", "Please upload file having extensions .csv only.");
    } else if (this.file.size < 22) {
      this.service.showMessage("error", "Please upload file with proper data");
    } else {
      this.fileUpload();
    }
  }

  fileUpload() {
    let url = params.old_prod_supplier + urls.uploadMSNBulk;
    let mapProductsRequest = {
      userId: this.service.getEmail(),
      groupId: this.selectedGroup.catalogGroupDetails.groupId,
    };
    const uploadData = new FormData();
    uploadData.append("file", this.file);
    uploadData.append("mapProductsRequest", JSON.stringify(mapProductsRequest));
    this.service.callRestful("POST", url, uploadData, null, null, null, true)
      .subscribe(
        (data) => {
          this.service.offSpinner();
          if (data["status"]) {
            this.service.showMessage("success", data["statusDescription"]);
            this.file = null;
            this.fileName = null;
            this.createService.setMSNList(data["data"]);
            let result = data['data'];
            this.processfailedMSN(result);
          } else {
            if (data["statusDescription"]) {
              this.service.showMessage("error", data["statusDescription"]);
            }
          }
        },
        (err) => {
          this.service.showMessage("error","Unable to proceed your request, Please try after some time");
        }
      );
  }

  clearStack(event) {
    event.target.value = null;
  }

  openPopup(template) {
    this.dataSource.data = this.failureMSNs;
     const dialogRef = this.dialog.open(template, {
       width: "710px",
       height: "auro",
       panelClass: "addFieldClass",
     });
 
     dialogRef.afterClosed().subscribe((result) => {
     });
   }
  
}
