import { Component, OnInit } from '@angular/core';
import { urls } from '../../../urls'
import { params } from '../../../params';
import { GlobalStateService } from '../../../../global-state.service';
import { DataService } from '../../../../data.service';
import { MappingService } from '../../../cpn-mapping.service';
import { Utility } from '../../../utility';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {


  selectedFileType:string;
  selectedBusinessType:string = '1';



  failedMSNs: boolean = false;

  file: any;
  fileResponse: boolean = false;
  response: any = {};
  _fileType: FormGroup;
  modules: any = [];
  disabledFlag = true;
  selectedbizz: string = "";
  size:any;

  

  constructor(private fb: FormBuilder, private _state: GlobalStateService, private _dataService: DataService, private _service: MappingService) { }

  ngOnInit() {
    this._fileType = this.fb.group({
      fileType: ['1', Validators.required],
    });

    this.selectedFileType = '1';

    this.modules = this._dataService.getModules();
    const module = this.modules.filter(x => x.moduleName === 'Product');
    if (module.length > 0) {
      const subModule = module[0]['subModuleList'].filter(y => y.moduleName === 'Bulk Product Operation');
      if (subModule.length > 0 && subModule[0]['isWrite']) {
        this.disabledFlag = false;
      }
    }
  }

  onFileChanged(event) {
    console.log(event)
    this.file = event.target.files[0];
    let flag = Utility.fileValidation(this.file);
    if (!flag) {
      this.file = null;
      this._dataService.showMessage("error", "Please upload file having extensions .csv only.");
    }
  }
  

  fileUpload() {
    if(this.selectedFileType=='5') {
      this.fileResponse = false;
    }
    if(this.selectedFileType!='5') {
      this.failedMSNs = false;
    }
 
    let url: string;
    let uploadedFor: string;
    let request = {
      "uploadedBy": this._dataService.getEmail(),
      "uploadFilePath": "",
      "uploadFileName": "",
    }
    if (this._fileType.controls.fileType.value == "1") {
      url = params.old_prod_supplier + urls.OLD.uploadBulkNewProducts;
      request['uploadedFor'] = "create";
      request['country'] = this._dataService.getSelectedCountryCode();
      request['parentBusinessCode'] = this._dataService.getSelectedParentBusinessCode()
    } else if (this._fileType.controls.fileType.value == "2") {
      request['country'] = this._dataService.getSelectedCountryCode()
      request['eventType'] = 'Product';
      request['operationType'] = 'Update';
      request['parentBusinessCode'] = this._dataService.getSelectedParentBusinessCode()
      url = params.platform_data_feed + urls.bulkProductsUpdate;
    } else if (this._fileType.controls.fileType.value == "3") {
      request['uploadedFor'] = "update active status";
      request['country'] = this._dataService.getSelectedCountryCode();  
      url  = params.old_prod_supplier + urls.OLD.updateActiveProductStatus;
      
    }
    else if (this._fileType.controls.fileType.value == "4") {
      request['uploadedFor'] = "Analytics Param File"
      url = params.old_prod_supplier + urls.OLD.marketingParameter;
    }
    else if(this._fileType.controls.fileType.value == "5") {
      request['uploadedFor'] = "Business View Name"
      request['viewName'] = "CIS"
      url = params.old_prod_supplier + urls.OLD.uploadBusinessView;
    }
    else {
      this._dataService.showMessage("error", "Please select project operation from drop down list");
    }

    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    uploadData.append('request', JSON.stringify(request));

    this._dataService.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          this._dataService.showMessage("success", "File uploaded successfully");
          if (data['data']['numFailed'] || (data['data']['failureDetails'] != null && this.selectedFileType=='5')) {
          
            if (data['data']['numFailed'] && this.selectedFileType != '5'){
            this.fileResponse = true;
          } 

            if(this.selectedFileType==='5' && data['data']['failureDetails'] != null && Object.keys(data['data']['failureDetails']).length > 0)
            {
              this.failedMSNs = true;
            }
            else {
              this.failedMSNs=false;
            }
            this._state.notifyMsnList(data['data']);
          }
          else {
            alert("File uploaded successfully");
            this.close();
          }
        }
        else {
          if(this.selectedFileType=='5'){
            this._dataService.showMessage("error",data['validationResponse']);
          }
          else {
            this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }}
      },
      (err) => {

        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  

  downloadViewProductTemplate(){
    let csvData = Utility.convertToCSV([],["Id Product","Enable"]);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, 'Business_Views_Update');
  }

  close() {
    this.file = null;
    this.fileResponse = false;
    this.failedMSNs = false;
  }

  clearStack(event) {
    event.target.value = null;
  }


}
