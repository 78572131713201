import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { MappingService } from 'src/app/pages/cpn-mapping.service';
import { params } from 'src/app/pages/params';
import { urls } from 'src/app/pages/urls';
import { Utility } from 'src/app/pages/utility';
@Component({
  selector: 'app-grouping-filter',
  templateUrl: './grouping-filter.component.html',
  styleUrls: ['./grouping-filter.component.scss']
})
export class GroupingFilterComponent implements OnInit {

  @Output() notifyGroup: EventEmitter<any> = new EventEmitter();
  @Output() notifyDefault: EventEmitter<any> = new EventEmitter();
  @ViewChild('groupname') groupname: ElementRef;   
  searchBrand$: Subject<string> = new Subject();
  searchCategory$: Subject<string> = new Subject();
  searchCategory: string = '';
  searchBrand: string = '';
  filteredCategories = [];
  categoryCodes = [];
  countryCode: number;
  brandCode: number;
  brandList: any = [];
  categoryCode: any;
  group: String = '';
  userEmail: string;
  groupedFor:string='';
  selectedOption = "";

  constructor(private service: DataService, public mappingService: MappingService, private router: Router) { }

  ngOnInit() { 
    this.countryCode = this.service.getSelectedCountryCode();
    this.userEmail = this.service.getEmail();
    this.searchDebouncer();
    this.onBrandChanges();
  }

  searchDebouncer() {
    this.searchCategory$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term: any) => {
        if(term.length > 2) {this.getFilteredCategories();}
      });
  }

  updateSearch(event) {
    this.searchCategory$.next(event.target.value);
  }

  getFilteredCategories() {
    let url = params.old_prod_supplier + urls.OLD.searchCategories;
    let body = {
      searchQuery: this.searchCategory,
      'countryCode': this.countryCode,
      'parentBusinessCode':this.service.getSelectedParentBusinessCode()

    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp && resp.data !== null && resp.data !== {}) {
        this.categoryCodes = Object.keys(resp.data)
        this.filteredCategories = Object.values(resp.data);
      }
      this.service.offSpinner();
    });
  }

  selectedCategory(category, i){
    this.searchCategory = category;
    this.categoryCode = this.categoryCodes[i]; 
    this.filteredCategories = this.categoryCodes = [] 
  }

  updateBrandSearch(event) {
    this.searchBrand$.next(event.target.value);
  }

  onBrandChanges(): void {
    this.searchBrand$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val: any) => {
      if (val && val.length > 1) {
        let url = params.old_prod_supplier + urls.OLD.getBrandList;
        let parameters = new HttpParams().set('name', val).set('status', 'false').set('countryCode', this.countryCode.toString());
        this.service.callRestful("GET", url, null, null, null, parameters, false).subscribe(this.processBrandList.bind(this));
      }
    });
  }

  processBrandList(data: any) {
    this.service.offSpinner();
    this.brandList = data.responseList;
  }

  selectedBrand(brandName, brandId){
    this.searchBrand = brandName;
    this.brandCode = brandId; 
    this.brandList = [];
  }

  getSearchCpn(e : any ){
  this.brandList = [];
  if(!this.brandCode && !this.group && !this.categoryCode && !this.groupedFor){
    this.notifyDefault.emit(null);
  }
  else{
  
  let body = {
    "brandId": this.brandCode ? this.brandCode : null,
    "groupName": this.group ? this.group : null,
    "categoryCode": this.categoryCode ? this.categoryCode : null,
    "groupedFor" : this.groupedFor ? this.groupedFor : null
   }
   this.notifyGroup.emit(body);
  }
  }

  resetFilters(){
    this.group = null;
    this.searchCategory = null;
    this.searchBrand = null;
    this.notifyDefault.emit(null);
  }

  navigateTo(){
    this.router.navigate(['/pages/catalog-grouping/create'])
  }

  downloadGroups(){
    let body = {
      "brandId": this.brandCode ? this.brandCode : null,
      "groupName": this.group ? this.group : null,
      "categoryCode": this.categoryCode ? this.categoryCode : null,
      "groupedFor": this.groupedFor ? this.groupedFor : null
     }
     let url = ''
     if(body.brandId === null && body.brandId === null && body.brandId === null ){
      url = params.old_prod_supplier + urls.downloadGroups +'?pageNo=1&limit=5';
     }else{
      url = params.old_prod_supplier + urls.downloadGroups;
     }
    this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp['status']) {
        this.exportToCsv(resp['data']);
        this.service.showMessage('success', resp['statusDescription']);
      }else {
        this.service.showMessage('error', 'no data found to export');
        //this.service.showMessage('error', resp['statusDescription']);
      }
      this.service.offSpinner();
    });
  }

  exportToCsv(dataSource){
    if (dataSource && dataSource.length) {
      let groupListDetails = [];
      for(let i=0; i<dataSource.length ; i++){
        var keys = Object.keys(dataSource[i]['productDataInfoMap']);
        let catalogDetails = dataSource[i]['groupDetailsResponse']
        if(keys.length === 0){
          let body = {
            groupId : catalogDetails['catalogGroupDetails'].groupId,
            groupName : catalogDetails['catalogGroupDetails'].groupName,
            groupedFor: catalogDetails['catalogGroupDetails'].groupedFor,
            categoryId : catalogDetails['catalogGroupDetails'].categoryId,
            categoryName :  catalogDetails.categoryName,
            brandId : catalogDetails['catalogGroupDetails'].brandId,
            brandName : catalogDetails.brandName,
            msn : 'N/A',
            productName : 'N/A',
            variant:'N/A'
          }
          groupListDetails.push(body);
        }else{
        for(let j=0; j<keys.length; j++){
          let body = {
            groupId : catalogDetails['catalogGroupDetails'].groupId,
            groupName : catalogDetails['catalogGroupDetails'].groupName,
            groupedFor: catalogDetails['catalogGroupDetails'].groupedFor,
            categoryId : catalogDetails['catalogGroupDetails'].categoryId,
            categoryName :  catalogDetails.categoryName,
            brandId : catalogDetails['catalogGroupDetails'].brandId,
            brandName : catalogDetails.brandName,
            msn : dataSource[i]['productDataInfoMap'][keys[j]].idProduct,
            productName : dataSource[i]['productDataInfoMap'][keys[j]].productName,
            variant:dataSource[i]['productDataInfoMap'][keys[j]].variant
          }
          groupListDetails.push(body);
        }
       }
      }
        
      const headers = ['Group ID', 'Group Name','Grouped For', 'Category ID','Category Name','Brand ID', 'Brand Name', 'MSN', 'Product Name', 'Varient'];
      const apiParams = ['groupId', 'groupName','groupedFor', 'categoryId', 'categoryName', 'brandId', 'brandName', 'msn', 'productName', 'variant'];
      const csvData = Utility.convertToCSV(groupListDetails, apiParams, headers);
      Utility.downLoadFile(csvData, `Groups Download`);
    } else {
      this.service.showMessage('error', 'no data found to export');
    }
  }

  selectGroupingMode(mode){
    this.groupedFor=mode;
    console.log(this.groupedFor);
  }
}
