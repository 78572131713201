import { MatTableModule } from '@angular/material/table';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablerComponent } from './tabler/tabler.component';
import {   MatPaginatorModule } from '@angular/material';
@Injectable({
  providedIn: 'root'
})

export class SharedService { 
  imageUrlsListForCreate:any = [];
  imageUrlsListForEdit:any = [];
  imageUrlsListForClone:any = [];
}
