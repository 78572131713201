import { Component, OnInit } from '@angular/core';
import { urls } from '../../../urls'
import { params } from '../../../params';
import { GlobalStateService } from '../../../../global-state.service';
import { DataService } from '../../../../data.service';
import { MappingService } from '../../../cpn-mapping.service';
import { Utility } from '../../../utility';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  file: any;
  fileResponse: boolean = false;
  response: any = {};
  _fileType: FormGroup;
  modules: any = [];
  disabledFlag = true;

  constructor(private fb: FormBuilder, private _state: GlobalStateService, private _dataService: DataService, private _service: MappingService) { }

  ngOnInit() {
    this._fileType = this.fb.group({
      fileType: ['1', Validators.required]
    });

    this.modules = this._dataService.getModules();
    const module = this.modules.filter(x => x.moduleName === 'Offer Price');
    if (module.length > 0 && module[0]['isWrite']) {
        this.disabledFlag = false;
    }
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    let flag = Utility.fileValidation(this.file);
    if (!flag) {
      this.file = null;
      this._dataService.showMessage("error", "Please upload file having extensions .csv only.");
    }
  }

  fileUpload() {
    // let request = {
    //   "uploadedBy": this._dataService.getEmail(),
    //   "uploadedFor": "create",
    //   "uploadFilePath": "",
    //   "uploadFileName": ""
    // }
    // let url = params.old_prod_supplier + urls.OLD.uploadBulkNewProducts;

    let url: string;
    let uploadedFor: string;
    let request:any;
    if (this._fileType.controls.fileType.value == "1") {
      url = params.price_engine + urls.Online.offerPrice;
      uploadedFor = "create";
       request = {
        "uploadedBy": this._dataService.getEmail(),
        "uploadedFor": uploadedFor,
        "uploadFilePath": "",
        "uploadFileName": ""
      }
    }
    else if(this._fileType.controls.fileType.value == "2"){
      url = params.price_manager + urls.DigiMRO.offerPrice;
      uploadedFor = "create";
      request = {
        "uploadedBy": this._dataService.getEmail(),
        "operation": uploadedFor,
        "businessCode":"DIGI-MRO"
       
      }
    }
    else if(this._fileType.controls.fileType.value == "3"){
      url = params.price_manager + urls.DigiMRO.offerPrice;
      uploadedFor = "update";
      request = {
        "uploadedBy": this._dataService.getEmail(),
        "operation": uploadedFor,
        "businessCode":"DIGI-MRO"
       
      }
    }

    // } else if (this._fileType.controls.fileType.value == "2") {
    //   uploadedFor = "update";
    //   url = params.old_prod_supplier + urls.OLD.updateBulkProducts;
    // }
    // else if (this._fileType.controls.fileType.value == "3") {
    //   uploadedFor = "update active status"
    //   url = params.old_prod_supplier + urls.OLD.updateActiveProductStatus;
    // }
    else {
      this._dataService.showMessage("error", "Please select project operation from drop down list");
    }

   
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    uploadData.append('request', JSON.stringify(request));

    this._dataService.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          this._dataService.showMessage("success", "File uploaded successfully");
          if (data['data']['numFailed']) {
            this.fileResponse = true;
            //   this.response['result'] = data['data'];
            //  this._service.setResponseData(data['data']);
            this._state.notifyMsnList(data['data']);
          }
          else {
            alert("File uploaded successfully");
            this.close();
          }
        }
        else {
          if(data['statusCode']=='400')
            this._dataService.showMessage("error", data['statusDescription']);

          else 
            this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  close() {
    this.file = null;
    this.fileResponse = false;
  }

  clearStack(event) {
    event.target.value = null;
  }

  downloadOfferPriceDetails(){
    let csvData = Utility.convertToCSV([],["Id Product","Minimum Quantity","Maximum Quantity","Sales Price"]);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, 'DigiMRO_Template');
  }
  
  
}
