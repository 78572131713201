import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../data.service';
import { MappingService } from '../../cpn-mapping.service';
import { urls } from '../../urls';
import { params } from '../../params';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalStateService } from '../../../global-state.service';
import { MapCpnMsnService } from '../map-cpn-msn.service';
import { MatCheckbox } from '@angular/material';
import { Utility } from '../../utility';
import { CpnMSnMappingType } from '../../../theme/enum/cpn-msn-mapping-type/cpn-msn-mapping-type';

@Component({
  selector: 'app-msn-list',
  templateUrl: './msn-list.component.html',
  styleUrls: ['./msn-list.component.scss']
})
export class MsnListComponent implements OnInit {

  //to toggle the state 
  toggletsWrap: boolean = false;
  // to show multi select checkbox in case of bundle msn
  assignEnabled: boolean = false;
  @Input() showMultiSelectCheckBox: boolean = false;
  displayList: boolean = true;
  filter: any;
  // default msn list
  msnList: any[];
  // child msnList against parent msn object
  childMsnList: any = {};
  cisChildMsnList: any = {};
  cpnDetail: any;

  index: number = 50;
  pageSize: number = 50;
  totalMsnCount: number;

  // total msn in bundle
  msnCountBundle: number = 0;
  // bundle msn object
  bundleMsn = {};
  // msnId which are checked
  msnNameList = [];

  showChildMsn = false;

  currentMsn = [];
  currentCisMsn = [];

  showOnlineChild:boolean = false;
  showCisChild:boolean = false;


  // boolean to show if the msns are already assigned to given cpn
  // true in case of edit mapping of msn
  isEditMapping: boolean = false;

  enableSearch: boolean = false;

  // already assigned msn list
  assginedMsn: any = [];

  enterpriseType: any;

  mappingType = '';

  msnSubscribedData: any;

  // emit total msn count
  @Output() emitMsnCountBundle = new EventEmitter<any>();
  mappingTypeALL: any;


  constructor(public service: DataService, public mappingSerivce: MappingService,
    private router: Router, private _state: GlobalStateService,
    public innerService: MapCpnMsnService,
    private paramRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.msnList = [];
    this.msnSubscribedData = this._state._msnDataStream.subscribe(this.setMsnListAndFilter.bind(this));
    this.innerService._msnBundleStream.subscribe(this.removeMsnFromBundle.bind(this));
    this.innerService.subscribe('callMultiMsn', this.assignMultiMsn.bind(this));
    //   this.isEditMapping = (this.paramRoute.snapshot.paramMap.get('flag') === 'true') ? true : false;
    this.mappingType = (this.paramRoute.snapshot.paramMap.get('flag'));
    if (this.paramRoute.snapshot.params.type == "ALL") {
      this.mappingTypeALL = this.paramRoute.snapshot.params.type;
    }


    //   const _selectEnterpriseType = Object.keys(CpnMSnMappingType).find(key => CpnMSnMappingType[key] === flag);
    if (this.mappingType === CpnMSnMappingType._unmapped) {
      this.isEditMapping = false;
    } else if (this.mappingType === CpnMSnMappingType._edit_mapping) {
      this.isEditMapping = true;
    } else if (this.mappingType === CpnMSnMappingType._future_mapping) {
      this.isEditMapping = false;
    } else {
      this.mappingType = '';
      //   this.service.showMessage('error', 'Something happens wrong with Cpn Msn type which get from URL');
    }
    if (this.isEditMapping) {
      this.innerService._assignedMsnStream.subscribe(this.showAlreadyAssignedMsn.bind(this));
    }

  }

  // subscribed from parent component to populate already assigned msn 
  showAlreadyAssignedMsn(msnDetailList) {
    if (msnDetailList.length > 0) {
      const assignedMsn = Utility.modifyMsnList(msnDetailList);
      for (let i = 0; i < assignedMsn.length; i++) {
        assignedMsn[i]['moglixPartNumber'] = assignedMsn[i]['msnId'];
        assignedMsn[i]['productName'] = assignedMsn[i]['msnName'];
        assignedMsn[i]['itemPerPack'] = assignedMsn[i]['itemInPack'];
        assignedMsn[i]['enterpriseType'] = assignedMsn[i]['msnType'];
      }
      this.assginedMsn = assignedMsn;
      // If bundle msn is assigned then check them by default
      const len = this.assginedMsn.length;
      if (len > 1) {
        for (let j = 0; j < len; j++) {
          this.bundleMsn[this.assginedMsn[j].moglixPartNumber] = this.assginedMsn[j];
          this.msnNameList.push(this.assginedMsn[j].moglixPartNumber);
        }
        this.msnCountBundle = len;
        this.emitMsnCountBundle.emit(this.msnCountBundle);
        this.innerService.setBundle(this.bundleMsn);
      }
    }
  }

  assignMultiMsn(bundleName) {
    const bundle = this.innerService.getBunble();
    const msnList = [];
    const msnObjects = {};
    let i = 0;
    if (bundle != null) {
      for (const key in bundle) {
        if (bundle.hasOwnProperty(key)) {
          msnObjects[i] = { 'msnId': bundle[key]['moglixPartNumber'] };
          msnObjects[i] = { 'msnName': bundle[key]['productName'] };
          i++;
        }
      }
      msnList.push(msnObjects);
      this.callMultiAssignApi(msnList, (i > 1), bundleName);
    }
  }
  // TODO remove this method
  ngAfterContentChecked() {
    if (this.showMultiSelectCheckBox == false) {
      this.msnCountBundle = 0;
      this.msnNameList.length = 0;
      // clear the bunble
      this.bundleMsn = {};
      this.innerService.clearBundle();
    }
  }

  // subscribed from msn filter to populate the list and filter
  setMsnListAndFilter(listAndFilter) {
    const bundle = this.innerService.getBunble();
    // remove all the child elements from paerent when a new search call come 
    this.msnList = [];
    this.childMsnList = {};
    this.cisChildMsnList = {};
    this.currentMsn.length = 0;
    this.currentCisMsn.length = 0;
    this.assignEnabled = listAndFilter['assignEnabled'];
    this.msnList = listAndFilter['msnList'];
    this.filter = listAndFilter['filter'];
    this.cpnDetail = listAndFilter['cpnDetail'];
    this.enterpriseType = listAndFilter['enterpriseType'];
    this.totalMsnCount = this.filter['totalCount'];
    //    this.enableSearch = listAndFilter["enableSearch"];

  }

  // open msn detail
  openMsnDetail(msn) {
    if (msn.moglixPartNumber != null) {
      this.router.navigateByUrl('/pages/msn/detail/' + msn.moglixPartNumber);
    }
    //alert(msn['iconImageUrl']);
  }


  togglets(type, index) {
    let id;
    let toggleText;
    let truncate;
    if (type == 'assigned') {
      id = 'toggleSpecifierAssigned_' + index;
      toggleText = document.getElementById('toggleTextAssigned_' + index);
      truncate = document.getElementById('truncateAssigned_' + index);
    } else if (type == 'default') {
      id = 'toggleSpecifier_' + index;
      toggleText = document.getElementById('toggleText_' + index);
      truncate = document.getElementById('truncate_' + index);
    } else if (type == 'child') {
      id = 'toggleSpecifierChild_' + index;
      toggleText = document.getElementById('toggleTextChild_' + index);
      truncate = document.getElementById('truncateChild_' + index);
    }
    const currentComp = document.getElementById(id);
    const text = currentComp.innerText;
    if (text == 'MORE') {
      toggleText.classList.remove('less');
      toggleText.classList.add('more');
      currentComp.innerText = 'LESS';
      truncate.classList.remove('show');
      truncate.classList.add('hide');
    } else {
      toggleText.classList.remove('more');
      toggleText.classList.add('less');
      currentComp.innerText = 'MORE';
      truncate.classList.remove('hide');
      truncate.classList.add('show');
    }
  }

  assignMsn(msn) {

    if (this.mappingType === CpnMSnMappingType._unmapped || this.mappingType === CpnMSnMappingType._edit_mapping || this.mappingType === '') {
      const msnObject = {};
      if (msn.moglixPartNumber != null) {
        msnObject['msnId'] = msn.moglixPartNumber;
      }
      if (msn.productName != null) {
        msnObject['msnName'] = msn.productName;
      }
      const msnList = [];
      msnList.push(msnObject);
      this.callMultiAssignApi(msnList, false, null);
    } else if (this.mappingType === CpnMSnMappingType._future_mapping) {
      this.saveFutureCpnMsnMapping(msn);
    } else {
      this.service.showMessage('error', 'Something happens wrong with Cpn Msn type which get from URL');
    }
  }

  saveFutureCpnMsnMapping(msn) {
    const url = params.prod_supp + urls.saveCpnMsnMapping;
    if ('' === msn.moglixPartNumber || null == msn.moglixPartNumber) {
      this.service.showMessage('error', 'MSN Id is missing in request body');
    } else if (this.cpnDetail.productCpn == null || this.cpnDetail.productCpn == ''
      || this.cpnDetail.productId == null || this.cpnDetail.productId == '') {
      this.service.showMessage('error', 'ProductId or product cpn is missing  in request body');
    } else {
      const body = {
        'productCpn': this.cpnDetail.productCpn,
        'productId': this.cpnDetail.productId,
        'msn': msn.moglixPartNumber,
        'userName': this.service.getEmail(),
        'userEmail': this.service.getEmail()
      };
      this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processFutureCpnMsnAssignResult.bind(this));
    }
  }

  processFutureCpnMsnAssignResult(data?: any) {
    this.service.offSpinner();
    if (data.status === false) {
      this.service.showMessage('error', data.errors[0].description);
    } else {
      this.service.showMessage('success', data.message);
      this.mappingSerivce.setMappingDetail(this.cpnDetail);
      this.router.navigateByUrl('/pages/cpn/unmapped-cpn/edit/mapping/' + this.cpnDetail.id);
    }
  }

  callMultiAssignApi(msnList, isBundle, bundleName) {
    const url = params.prod_supp + urls.assignMsn;
    if (this.cpnDetail.productCpn == null || this.cpnDetail.productCpn == ''
      || this.cpnDetail.productId == null || this.cpnDetail.productId == '') {
      this.service.showMessage('error', 'ProductId or product cpn is missing');
    } else {
      const body = {
        'id': this.cpnDetail.id,
        'msnList': msnList,
        'msnType': 1,
        'enterpriseType': this.enterpriseType,
        'bundleFlag': false,//isBundle,
        'bundleName': bundleName,
        'version': this.cpnDetail.version,
        'userName': this.service.getEmail(),
        'userEmail': this.service.getEmail(),
        'countryCode': this.service.getSelectedCountryCode(),
        "suggestionFlag": false
      };
      this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processAssignResult.bind(this));
    }
  }

  processAssignResult(data?: any) {
    this.service.offSpinner();
    if (data.status === false) {
      const errorMsg = data.errors[0].message + ': ' + data.errors[0].description;
      this.service.showMessage('error', errorMsg);
    } else {
      this.service.showMessage('success', data.message);
      this.mappingSerivce.setMappingDetail(data.cpnDetailVO);
      this.router.navigateByUrl('/pages/cpn/unmapped-cpn');
    }
  }

  onScrolldemo() {
    alert('scrolled');
  }

  onScroll() {
    if (this.index < this.totalMsnCount) {
      this.service.showMessage('info', 'Loading more Msn...');
      const url = params.prod_supp + urls.fetchElaticMsn;
      const body = this.craeteParameters();
      this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processResult.bind(this));
    }
  }

  craeteParameters() {
    const body = {};
    if (!(this.cpnDetail.productName == null || this.cpnDetail.productName == '')) {
      body['productName'] = this.cpnDetail.productName;
    }
    if (!(this.cpnDetail.brandName == null || this.cpnDetail.brandName == '')) {
      body['brandName'] = this.cpnDetail.brandName.toLowerCase();
    }
    if (this.filter['itemPerPack'] != null) {
      body['itemPerPack'] = this.filter['itemPerPack'];
    }
    if (this.filter['uom'] != 'Select') {
      body['uom'] = this.filter['uom'];
      if (this.filter['uomQuantity'] != null) {
        body['uomQuantity'] = this.filter['uomQuantity'];
      }
    }
    // TODO remove this condition
    if (this.showMultiSelectCheckBox) {
      body['enterpriseType'] = 'global';
    }
    if (this.filter['enterpriseType'] != 'Select') {
      body['enterpriseType'] = this.filter['enterpriseType'];
    }
    body['index'] = this.index;
    body['pageSize'] = this.pageSize;
    body['countryCode'] = this.service.getSelectedCountryCode()
    return body;
  }

  processResult(data: any) {
    this.service.offSpinner();
    let products: any[];
    this.index = this.index + 50;
    if (data.status === false) {
      this.service.showMessage('error', 'Error in fetching more msn on scroll');
    } else {
      if (data['totalCount'] == 0) {
        this.service.showMessage('success', 'No result found for the given inputs');
      } else {
        products = data['products'];
        var i = 0;
        for (i = 0; i < products.length; i++) {
          var imgUrl = products[i]['mainImageLink'];
          if (imgUrl == null) {
            products[i]['iconImageUrl'] = 'src/assets/img/NoImg.png';
          } else {
            // var index = imgUrl.indexOf( "-" ); 
            // var ext = imgUrl.slice(index+1, imgUrl.length);
            // var sliced = imgUrl.slice(0, index+1);
            // products[i]["iconImageUrl"] = params.images + sliced + "icon."+ext;
            var index = imgUrl.indexOf('-') > 0 ? imgUrl.indexOf('-') : imgUrl.indexOf('.');
            //  var ext = imgUrl.slice(index+1, imgUrl.length);
            var largeImage = imgUrl.slice(index + 1, imgUrl.length);
            var imageExtIndex = largeImage.indexOf('.');
            var ext = largeImage.slice(imageExtIndex + 1, largeImage.length);
            var sliced = imgUrl.slice(0, index);
            products[i]['iconImageUrl'] = params.images + sliced + '-icon.' + ext;
          }
          if (products[i].attributes == null && products[i].shortDesc == null) {
            products[i]['longDesc'] = null;
          } else if ((products[i].attributes == null && products[i].shortDesc != null) ||
            (products[i].attributes != null && products[i].shortDesc == null)) {
            products[i]['longDesc'] = products[i].attributes != null ? products[i].attributes : products[i].shortDesc;
          } else {
            products[i]['longDesc'] = products[i].attributes;
          }
        }
        this.msnList = this.msnList.concat(products);
      }
    }
  }

  valuechanged(event, msn) {
    const e = <MatCheckbox>event;
    if (e && e.checked) {
      this.msnCountBundle = this.msnCountBundle + 1;
      this.bundleMsn[msn.moglixPartNumber] = msn;
      this.msnNameList.push(msn.moglixPartNumber);
      this.emitMsnCountBundle.emit(this.msnCountBundle);
      this.innerService.setBundle(this.bundleMsn);
    } else {
      this.removeMsnFromBundle(msn.moglixPartNumber);
    }

  }

  // subscribed from bundle msn dailog to remve the bundle count;
  removeMsnFromBundle(moglixPartNumber) {
    this.msnCountBundle = this.msnCountBundle - 1;
    delete this.bundleMsn[moglixPartNumber];
    this.removeA(this.msnNameList, moglixPartNumber);
    this.emitMsnCountBundle.emit(this.msnCountBundle);
    this.innerService.setBundle(this.bundleMsn);
  }

  removeA(arr, item) {
    var index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }
  

  toggleShow(event, moglixPartNumber, id) {
    event.stopPropagation();
    const elemId = 'showChild_' + id;
    const elem = document.getElementById(elemId);

    // if (elem != null && elem.innerText.toString() === 'hide(online)') {
    //   elem.innerText = 'show child msn (online) ';
    //   this.showOnlineChild=false;
    // } else if (elem != null && elem.innerText.toString() === 'show child msn (online) ') {
    //   elem.innerText = 'hide(online)';
    //   this.showOnlineChild=true;
    //   if(this.showCisChild===true){
    //   this.showCisChild=false;
    //   document.getElementById('showCisChild_'+id).innerText = 'show child msn (CIS) '
    //   }
    // }

    this.showOnlineChild=!this.showOnlineChild;
    if(this.showOnlineChild){
        elem.innerText = 'hide(online)';
        if(this.showCisChild){
          this.showCisChild=false;
          document.getElementById('showCisChild_'+id).innerText = 'show child msn (CIS) '
        }
    }else{
        elem.innerText = 'show child msn (online) ';
    }
    console.log('inside toggleshow',this.showOnlineChild,this.showCisChild)
    if (this.currentMsn.includes(moglixPartNumber) && !this.showOnlineChild) {
      this.removeA(this.currentMsn, moglixPartNumber);
    } else {
      this.currentMsn.push(moglixPartNumber);
    }
    // make a rest call to fetch child msn if not present in childMsnList
    if (this.childMsnList[moglixPartNumber] == null) {
      this.service.showMessage('info', 'Loading child msn. please wait .....');

      const url = params.prod_supp + urls.fetchElaticChildMsn + '/' + moglixPartNumber;
      this.service.callRestful('GET', url, null, null, null).subscribe(this.processChildResult.bind(this, moglixPartNumber, id));
    }
  }

  showCisChildMsn(event, moglixPartNumber, id) {
    event.stopPropagation();
    const elemId = 'showCisChild_' + id;
    const elem = document.getElementById(elemId);
    console.log(elem.innerText.toString());
    // if (elem != null && elem.innerText.toString() === 'hide(CIS)') {
    //   elem.innerText = 'show child msn (CIS)';
    //   this.showCisChild=false;
    // } else if (elem != null && elem.innerText.toString() === 'show child msn (CIS)') {
    //   elem.innerText = 'hide(CIS)';
    //   debugger;
    //   this.showCisChild = true;
    //   if(this.showOnlineChild===true){
    //     this.showOnlineChild = false;
    //   document.getElementById('showChild_'+id).innerText = 'show child msn (online) '
    //   }
    // }
    this.showCisChild=!this.showCisChild;
    if(this.showCisChild){
        elem.innerText = 'hide(CIS)';
        if(this.showOnlineChild){
          this.showOnlineChild=false;
          document.getElementById('showChild_'+id).innerText = 'show child msn (online) '
        }
    }else{
        elem.innerText = 'show child msn (CIS)';
    }
    console.log('inside cis child show',this.showOnlineChild,this.showCisChild)
    if (this.currentCisMsn.includes(moglixPartNumber) && !this.showCisChild ) {
      this.removeA(this.currentCisMsn, moglixPartNumber);
    } else {
      this.currentCisMsn.push(moglixPartNumber);
    }
    // make a rest call to fetch child msn if not present in cisChildMsnList
    if (this.cisChildMsnList[moglixPartNumber] == null) {
      this.service.showMessage('info', 'Loading child msn. please wait .....');

      const url = params.prod_supp + urls.fetchElasticCisChildMsn + moglixPartNumber;
      this.service.callRestful('GET', url, null, null, null).subscribe(this.processCisChildResult.bind(this, moglixPartNumber, id));
    }

  }

  processChildResult(msn, id, data) {
    this.service.offSpinner();
    if (data != null && data.status) {
      if (data['totalCount'] == 0) {
        this.service.showMessage('info', 'No child msn present');
      } else {
        const products = data['products'];
        if (products.length == 0 || products.length == 1) {
          this.service.showMessage('info', 'no child msn present');
        }
        // remove the parent msn from child msn
        for (let j = 0; j < products.length; j++) {
          if (products[j].moglixPartNumber === msn) {
            products.splice(j, 1);
            continue;
          }
        }
        for (let i = 0; i < products.length; i++) {
          var imgUrl = products[i]['mainImageLink'];
          if (imgUrl == null) {
            products[i]['iconImageUrl'] = 'src/assets/img/NoImg.png';
          } else {
            var index = imgUrl.indexOf('-');
            var ext = imgUrl.slice(index + 1, imgUrl.length);
            var sliced = imgUrl.slice(0, index + 1);
            products[i]['iconImageUrl'] = params.images + sliced + 'icon.' + ext;
          }

          // if (products[i].attributes == null && products[i].shortDesc == null) {
          //   products[i]["longDesc"] = null;
          // } else if ((products[i].attributes == null && products[i].shortDesc != null) ||
          //   (products[i].attributes != null && products[i].shortDesc == null)) {
          //   products[i]["longDesc"] = products[i].attributes != null ? products[i].attributes : products[i].shortDesc;
          // } else {
          //   products[i]["longDesc"] = products[i].attributes;
          // }

          if (products[i].attributes !== null) {
            products[i]['longDesc'] = products[i].attributes;
          } else if (products[i].shortDesc !== null) {
            products[i]['longDesc'] = products[i].shortDesc;
          } else {
            products[i]['longDesc'] = null;
          }

          this.childMsnList[msn] = products;

        }
      }
    }
    else {
      this.service.showMessage('error', 'Failed to load child msn');
      const elemId = 'showChild_' + id;
      const elem = document.getElementById(elemId);
      if (elem != null && elem.innerText.toString() === 'hide(online)') {
        elem.innerText = 'show child msn(online)';
      }
    }
  }
  ngOnDestroy() {
    if (this.msnSubscribedData >= 0) { this.msnSubscribedData.unsubscribe(); }
    this.msnList = [];
  }



  processCisChildResult(msn, id, data) {
    this.service.offSpinner();
    if (data != null && data.status) {
      if (data['totalCount'] == 0) {
        this.service.showMessage('info', 'No child msn present');
      } else {
        const products = data['products'];
        if (products.length == 0 || products.length == 1) {
          this.service.showMessage('info', 'no child msn present');
        }
        // remove the parent msn from child msn
        for (let j = 0; j < products.length; j++) {
          if (products[j].moglixPartNumber === msn) {
            products.splice(j, 1);
            continue;
          }
        }
        for (let i = 0; i < products.length; i++) {
          var imgUrl = products[i]['mainImageLink'];
          if (imgUrl == null) {
            products[i]['iconImageUrl'] = 'src/assets/img/NoImg.png';
          } else {
            var index = imgUrl.indexOf('-');
            var ext = imgUrl.slice(index + 1, imgUrl.length);
            var sliced = imgUrl.slice(0, index + 1);
            products[i]['iconImageUrl'] = params.images + sliced + 'icon.' + ext;
          }

          // if (products[i].attributes == null && products[i].shortDesc == null) {
          //   products[i]["longDesc"] = null;
          // } else if ((products[i].attributes == null && products[i].shortDesc != null) ||
          //   (products[i].attributes != null && products[i].shortDesc == null)) {
          //   products[i]["longDesc"] = products[i].attributes != null ? products[i].attributes : products[i].shortDesc;
          // } else {
          //   products[i]["longDesc"] = products[i].attributes;
          // }

          if (products[i].attributes !== null) {
            products[i]['longDesc'] = products[i].attributes;
          } else if (products[i].shortDesc !== null) {
            products[i]['longDesc'] = products[i].shortDesc;
          } else {
            products[i]['longDesc'] = null;
          }

          this.cisChildMsnList[msn] = products;
        }
      }
    } else {
      this.service.showMessage('error', 'Failed to load child msn');
      const elemId = 'showCisChild_' + id;
      const elem = document.getElementById(elemId);
      if (elem != null && elem.innerText.toString() === 'hide(CIS)') {
        elem.innerText = 'show child msn (CIS)';
      }
    }
  }


}
