import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: 'src/app/pages/login/login.module#LoginModule' },
  {
    path: 'pages',
    component: PagesComponent,
    children: [


      { path: '', redirectTo: 'unmapped-cpn', pathMatch: 'full' },
      { path: 'cpn/unmapped-cpn', loadChildren: 'src/app/pages/unmapped/unmapped.module#UnmappedModule' },
      {path: 'cpn-msn-reports', loadChildren: 'src/app/pages/cpn-msn-mapping-download/cpn-msn-mapping-download.module#CpnMsnMappingDownloadModule'},
      { path: 'brand', loadChildren: 'src/app/pages/brand/brand.module#BrandModule' },
      { path: 'map-cpn-msn/:id/:flag', loadChildren: 'src/app/pages/map-cpn-msn/map-cpn-msn.module#MapCpnMsnModule' },
      { path: 'create', loadChildren: 'src/app/pages/create/create.module#CreateModule' },
      { path: 'msn', loadChildren: 'src/app/pages/msn/msn.module#MsnModule' },
      { path: 'report', loadChildren: 'src/app/pages/report/report.module#ReportModule' },
      { path: 'products', loadChildren: 'src/app/pages/products/products.module#ProductsModule' },
      { path: 'updateproducts', loadChildren: 'src/app/pages/updateproducts/updateproducts.module#UpdateProductsModule' },
      { path: 'category', loadChildren: 'src/app/pages/category/category.module#CategoryModule' },
      { path: 'attribute', loadChildren: 'src/app/pages/attribute/attribute.module#AttributeModule' },
      { path: 'supplier', loadChildren: 'src/app/pages/supplier/supplier.module#SupplierModule' },
      { path: 'template', loadChildren: 'src/app/pages/attribute/attribute.module#AttributeModule' },
      { path: 'offer-price', loadChildren: 'src/app/pages/offer-price/offer-price.module#OfferPriceModule' },
      { path: 'users', loadChildren: 'src/app/pages/users/users.module#UsersModule' },
      { path: 'static-field', loadChildren: 'src/app/pages/static-field/static-field.module#StaticFieldModule' },
      { path: 'lpa', loadChildren: 'src/app/pages/lpa/lpa.module#LpaModule' },
      { path: 'catalog-grouping', loadChildren: 'src/app/pages/catalog-grouping/catalog-grouping.module#CatalogGroupingModule' },
      { path: 'product-completeness-score', loadChildren: 'src/app/pages/product-completeness-score/product-completeness-score.module#ProductCompletenessScoreModule' },
    ],
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**', redirectTo: 'login', pathMatch: 'full'
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
