import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { params } from '../../../params';
import { urls } from '../../../urls';
import { DataService } from '../../../../data.service';

@Component({
  selector: 'product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  productId: any;
  product: any = {};
  productAttribute: any = {};
  attributesKeyList: any = {};

  constructor(private router: Router, private _dataService: DataService, private paramRoute: ActivatedRoute) { }

  ngOnInit() {
    this.productId = this.paramRoute.snapshot.paramMap.get("id");
    this.getProductDetail(this.productId);
  }

  getProductDetail(productId) {

    let url = params.old_prod_supplier + urls.OLD.getRawProductById + "?productId=" + productId;
    this._dataService.callRestful("GET_MSN", url, null, null, null, null, true).subscribe(
      (data) => {
        this._dataService.offSpinner();
  //  let data = this.data1;
    if (data['status']) {
      this._dataService.showMessage("success", "Fetch product details successfully");
      this.product = data['data'];
      this.productAttribute = data['data']['productDataMapByAttributeName'];
      this.attributesKeyList = Object.keys(this.productAttribute);
    }
    else {
      this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
    }
    },
    (err) => {
      this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
    });
  }


  getKey(key) {
    return this.productAttribute[key];
  }

  data1 =
    {
      "status": true,
      "statusCode": 200,
      "statusDescription": null,
      "data": {
        "idProduct": "be699405-cf83-4c70-9813-794ed66eeeb2",
        "categoryCode": "121190000",
        "brand": "Wellon",
        "manufacturer": "Wellon",
        "keyFeatures": null,
        "nextGenProduct":null,
        "prevGenProduct":null,
        "productDataMapByAttributeId": {
          "19130093-856e-4d87-b483-0900031a2805": "8000 g",
          "5f438fb7-ba65-4f64-b69c-ac4119c0b276": "15 l",
          "a6db0cff-702c-442e-a599-ea326aaf01f4": "Purification of Brackish / Tap / Municipal water supply",
          "b09e6fc4-5ae6-4c87-a005-dd1d38b14eba": "WP-042",
          "b256e92e-713b-4246-8bf4-dd9d006e6d1c": "15lph",
          "bb85c6a9-c33b-4e1c-a108-60d340e765d4": "1 Pieces",
          "f1cfe189-238b-4484-917c-754ceb15d740": "White"
        },
        "productDataMapByAttributeName": {
          "Brand Name": "Wellon",
          "Capacity": "15 l",
          "Category Code Level1": "121000000",
          "Category Code Level2": "121190000",
          "Category Name Level1": "Cleaning",
          "Category Name Level2": "Water Purification Systems & Accessories",
          "Colour": "White",
          "Internal Part Number": "WELL_WP-042",
          "Item Code": "WP-042",
          "Items in Pack": "1 Pieces",
          "MRP": "17500",
          "Manufacturer Name": "Wellon",
          "Product Name": "Wellon Sensible 7 Stages RO+UV+UF+TDS Controller Water Purifier, Capacity: 15 Litre",
          "Product Short Description": "Brand: Wellon||Item Code: WP-042||Capacity: 15 l",
          "Purification Capacity": "15lph",
          "Quality Image": "TRUE",
          "Suitable For": "Purification of Brackish / Tap / Municipal water supply",
          "Weight": "8000 g",
          "product_type": "online"
        },
        "status": "Successful",
        "failureReason": null,
        "uploadFileId": "d069925d-0215-46fa-9e9e-9d59965b52a4",
        "images": [
          {
            "links": {
              "xxlarge": "p/I/1/M/d/MINI1MLHIUILF-xxlarge.jpg",
              "xlarge": "p/I/1/M/d/MINI1MLHIUILF-xlarge.jpg",
              "large": "p/I/1/M/d/MINI1MLHIUILF-large.jpg",
              "medium": "p/I/1/M/d/MINI1MLHIUILF-medium.jpg",
              "small": "p/I/1/M/d/MINI1MLHIUILF-small.jpg",
              "thumbnail": "p/I/1/M/d/MINI1MLHIUILF-thumbnail.jpg",
              "icon": "p/I/1/M/d/MINI1MLHIUILF-icon.jpg",
              "default": "p/I/1/M/d/MINI1MLHIUILF.jpg"
            },
            "moglixImageNumber": "MINI1MLHIUILF",
            "altTag": null,
            "position": 0
          }
        ],
        "datasheets": [],
        "moglixPartNumber": "MSN2VE6R250CWF",
        "mapped": true,
        "deletedFlag": false,
        "internalPartNumber": "WELL_WP-042",
        "mrp": 17500,
        "createdOn": 1476874627320,
        "updatedOn": 1476874674935,
        "verificationFailureDetails": [],
        "uploadedBy": null,
        "verifiedBy": "MS8B500BH",
        "shippingDetails": null,
        "qualityImage": true,
        "productType": "online",
        "excisable": false,
        "productHsn": null,
        "isBulkPriceValid": true
      }
    }
}
