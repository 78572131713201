import { params } from './params';

export class Utility {

    constructor() { }
    public static modifyMsnList(msnDetailList) {
        let msnList = msnDetailList;
        let msnListSize = msnList.length;
        for (let i = 0; i < msnListSize; i++) {
            let msnDet: any = msnList[i];
            // if (msnDet.imagesUrls != null && msnDet.imagesUrls.length > 0) {
            //     let imgUrl = msnDet.imagesUrls[0];
            //     var index = imgUrl.indexOf(".");
            //     var ext = imgUrl.slice(index+1, imgUrl.length);
            //     var sliced = imgUrl.slice(0, index);
            //     msnList[i]["iconImageUrl"] = params.images + sliced + "-icon."+ext;
            // }

            if (msnDet.imagesUrls != null && msnDet.imagesUrls.length > 0) {
                let imgUrl = msnDet.imagesUrls[0];
                var index = imgUrl.indexOf("-") > 0 ? imgUrl.indexOf("-") : imgUrl.indexOf(".");
                //  var ext = imgUrl.slice(index+1, imgUrl.length);
                var largeImage = imgUrl.slice(index + 1, imgUrl.length);
                var imageExtIndex = largeImage.indexOf(".");
                var ext = largeImage.slice(imageExtIndex + 1, largeImage.length);
                var sliced = imgUrl.slice(0, index);
                msnList[i]["iconImageUrl"] = params.images + sliced + "-icon." + ext;
            }

            let attributeList = msnList[i]["attributeList"];
            if (attributeList && attributeList.length > 0) {
                let attributes: string = "";
                for (let i = 0; i < attributeList.length; i++) {
                    attributes = attributes.concat("||" + attributeList[i]);
                }
                attributes = attributes.substring(2, attributes.length);
                msnList[i]['attributes'] = attributes;
                if (msnList[i].attributes == null && msnList[i].shortDesc == null) {
                    msnList[i]["longDesc"] = null;
                } else if ((msnList[i].attributes == null && msnList[i].shortDesc != null) ||
                    (msnList[i].attributes != null && msnList[i].shortDesc == null)) {
                    msnList[i]["longDesc"] = msnList[i].attributes != null ? msnList[i].attributes : msnList[i].shortDesc;
                } else {
                    msnList[i]["longDesc"] = msnList[i].attributes;
                }
            }
        }
        return msnList;
    }

    public static fileValidation(fileInput) {
        var filePath = fileInput.name;
        var allowedExtensions = /(\.csv)$/i;
        if (!allowedExtensions.exec(filePath)) {
            //  alert('Please upload file having extensions .csv only.');
            // fileInput.value = '';
            return false;
        } else {
            return true;
        }
    }

    public static convertToCSV(objArray, headerNames, originalHeders = []) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let headerList = headerNames;
        let str = '';
        let row = 'S.No,';
        if(originalHeders.length) {
          for (let index in originalHeders) {
            row += originalHeders[index] + ',';
          }
        } else {
          for (let index in headerList) {
            row += headerList[index] + ',';
          }
        }

        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
          let line = (i + 1) + '';
          for (let index in headerList) {
            let head = headerList[index];
            if (typeof (array[i][head]) == 'string') {
              array[i][head] = array[i][head].replaceAll(',', '-')
            }
            line += ', ' + array[i][head] || '-';
          }
          str += line + '\r\n';
        }
        return str;
      }
    
    public static downLoadFile(csvData, fileName) {
        let blob = new Blob(['\ufeff' + csvData], {
          type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf(
          'Safari') != -1 &&
          navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
          dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", fileName + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      }
}