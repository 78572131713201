import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from '../../../../global-state.service';
import { MappingService } from '../../../cpn-mapping.service';


@Component({
  selector: 'app-business-view-failed-msns',
  templateUrl: './business-view-failed-msns.component.html',
  styleUrls: ['./business-view-failed-msns.component.scss']
})
export class BusinessViewFailedMsnsComponent implements OnInit {

  responseSubscribe: any;
  response: any = {};
  failureCaseResponse: any = {};
  failureKeys: any = {}
  size:number;
  numPassed:number=0;
  constructor(private _state: GlobalStateService, private _service: MappingService) {
  }



  ngOnInit() {
    this.responseSubscribe = this._state._msnDataStream.subscribe(resp => {
      this.response = resp;
      this.failureCaseResponse = this.response['failureDetails'];
      this.failureKeys = Object.keys(this.failureCaseResponse);
      if(this.response.passedList!=null) {
        this.numPassed =  this.response.passedList.length
      }
  });

}

getKey(key) {
  return this.failureCaseResponse[key];
}

getSize(response){
  return Object.values(response.data.failureDetails).length;

}

ngOnDestroy() {
  if (this.responseSubscribe >= 0) this.responseSubscribe.unsubscribe();
  this._service.clearResponeData();
}

}
