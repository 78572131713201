/**
 * Created by nitish ranjan on 28/09/18.
 */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { DataService } from '../data.service';
import { params } from './params';
import { urls } from './urls';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  countryfilter:boolean;
  countryList:any;
  businesCodeMap:Map<any, any> = new Map<any,any>();
  businessList: Array<any>=[];
  businessNameList:Array<string>=[];
  businessNameListOnUI:Array<string>=[];
  rootCategory:string;
  parentBusinessCode:string;
  country:any;
  businessNameListOnUIUSA: string[];
  businessNameListOnUIUAE: string[];
  businessNameListOnUIIndia: string[];
  constructor(private service :DataService,private router: Router) {
    
  }
  selectCountry(country){
    //let url = params.old_prod_supplier + urls.OLD.getBusinessDetails;
    this.service.setSelectedCountryCode(country['countryCode'])
    this.country = country['countryCode'];
    this.service.setSelectedCountryName(country['countryName'])
    
    //this.service.callRestful('GET', url, null, null, null, null).subscribe(this.processBusniessList.bind(this));

  }
  selectBusiness(code){
    this.service.setSelectedBusinessDisplayName(code);
    // for (var i=0; i < this.businessList.length ; ++i){
    //   if(this.businessList[i]['businessCode'].toString().trim() == code.toString().trim()){
    //      this.parentBusinessCode = this.businessList[i]['businessCode'];

    //   }
    // }
    for (const [key, value] of Object.entries(this.businesCodeMap)) { 
      if( key==this.country){
          this.businessList = value;
          
          for (var i=0; i < this.businessList.length ; ++i){
            if(this.businessList[i]['businessDisplayName']==code){
              this.service.setSetectedParentBusinessCode(this.businessList[i]['businessCode']);

            }
          
          }

      }
    }
    this.countryfilter = true;  
    this.businessList=[];
    this.businessNameList=[]
  }

 ngOnInit() {
  let url = params.old_prod_supplier + urls.OLD.getBusinessDetails;
  this.service.callRestful('GET', url, null, null, null, null).subscribe(this.processBusniessList.bind(this));
  this.service.offSpinner();

    if(this.service.getToken() && null!=this.service.getSelectedCountryCode() && null!=this.service.getSelectedBusinessDisplayName()){
      this.countryfilter =true;
    
    }
    else{
      
      if(this.service.getToken() && null!=this.service.getCountryList() && this.service.getCountryList().length>0){
        
        this.countryList = this.service.getCountryList();
        
      }
      // if(this.service.getToken() && this.service.getCountryList().length == 1){
      //   this.countryList = this.service.getCountryList();
      // this.service.setSelectedCountryCode(this.countryList[0]['countryCode'])
      // this.service.setSelectedCountryName(this.countryList[0]['countryName'])
      
      //   this.countryfilter = true;
      //  // window.location.reload();

      // }
    }
    if(!this.service.getToken()){
      this.router.navigateByUrl('/login');
    }

  }
  getCountryFlag(img){
    return "assets/img/"+img+".svg";
  }
  processBusniessList(data: any) {
   
       this.businesCodeMap = data['data'];
       
       for (const [key, value] of Object.entries(this.businesCodeMap)) { 
        if( key=="356"){
          console.log("356")
            this.businessList = value;
            for (var i=0; i < this.businessList.length ; ++i){
              this.businessNameList.push(this.businessList[i]['businessDisplayName']);
            
            }
            this.businessNameListOnUIIndia = this.businessNameList;
            this.businessNameListOnUI['India'] = this.businessNameListOnUIIndia;
            this.businessNameList=[];

        }
         if(key=="784"){
          console.log("784")

          this.businessList = value;
          for (var i=0; i < this.businessList.length ; ++i){
            this.businessNameList.push(this.businessList[i]['businessDisplayName']);
          
          }
          this.businessNameListOnUIUAE= this.businessNameList;
          this.businessNameListOnUI['Uae'] = this.businessNameListOnUIUAE;

          this.businessNameList=[];


      }
      if( key=="840"){
        this.businessList = value;
        for (var i=0; i < this.businessList.length ; ++i){
          this.businessNameList.push(this.businessList[i]['businessDisplayName']);
        
        }
        this.businessNameListOnUIUSA= this.businessNameList;
        this.businessNameListOnUI['Usa'] = this.businessNameListOnUIUSA;

        this.businessNameList=[];


    }
    }

    // this.businessNameListOnUI = this.businessNameList;
    this.businessNameList=[];
    this.service.offSpinner();
    
  }

  getBusinessNameListForCountry(countryName: string): string[] {
    const businessNameList = this.businessNameListOnUI[countryName] || [];
    return businessNameList;
  }
  
}
