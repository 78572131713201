import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../data.service';
import { GlobalStateService } from '../../../global-state.service';
import { params } from 'src/app/pages/params';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  modules = [];
  constructor(private router: Router, private _dataService: DataService, private _state: GlobalStateService) {
   
  }

  ngOnInit() {
    this.modules = this._dataService.getModules();
  }

  navigateUrl(module: any) {
    switch (module.moduleName) {
      case 'Create Group':
        if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/catalog-grouping/create');
        } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'View Group':
        if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/catalog-grouping/view');
        } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
      break;
      case 'Unmapped CPN':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/cpn/unmapped-cpn');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Search MSN':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/msn/search');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Create MSN':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('pages/create');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Advance MSN Search':
          if (module.isRead || module.isWrite) {
            window.open(params.cisPortal);
          } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
          }
          break;

      case 'Download Report':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/report');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Bulk Product Operation':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/products');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Verify Product':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/products/verify');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Product Download':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/products/download');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Create Brand':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/brand/create');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Update Brand':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/brand');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'View Brand':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/brand/view-brand');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Create Category':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/category/create');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'View Category':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/category/update');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Update Category':
          if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/category/update');
          } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
          }
          break;
          case 'Grid Product':
            if (module.isRead || module.isWrite) {
              this.router.navigateByUrl('/pages/category/grid');
            } else {
              this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
            }
            break;
      case 'Template':
        if (module.isRead || module.isWrite) {
          this._state.templateRefreshEvent(true);
          this.router.navigateByUrl('/pages/template');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Supplier Mapping':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/supplier/mapping');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Supplier Download':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/supplier/download');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
      case 'Supplier Approval':
          if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/supplier/approval');
          } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
          }
        break;
      case 'Supplier Demapping':
          if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/supplier/demapping');
          } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
          }
        break;
      case 'Commercial Category Bulk Update':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/supplier/commercial');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
      break;
      case 'Offer Price':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/offer-price');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Static Field':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/static-field');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Verify Mapping':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('/pages/cpn/unmapped-cpn/verifycpn');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'CPN-MSN Reports':
          if (module.isRead || module.isWrite) {
            console.log("module is read" + module.isRead + "and " + module.isWrite)
            this.router.navigateByUrl('pages/cpn-msn-reports');
          } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
          }
          break;
        case 'LPA':
        if (module.isRead || module.isWrite) {
            this.router.navigateByUrl('/pages/lpa');
        } else {
            this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Competitor Score':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('pages/product-completeness-score/competitor');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;
        case 'Completeness Score':
        if (module.isRead || module.isWrite) {
          this.router.navigateByUrl('pages/product-completeness-score');
        } else {
          this._dataService.showMessage('error', 'You are not authorized person to perform this activity');
        }
        break;

      default:
        this._dataService.showMessage('error', 'Please select validate functionality');
    }
  }

  navigateToUnmappedCpn() {
    this.router.navigateByUrl('/pages/cpn/unmapped-cpn');
  }

  navigateToSearchPage() {
    this.router.navigateByUrl('/pages/msn/search');
  }

  navigateToCreatePage() {
    this.router.navigateByUrl('/pages/create');
  }

  navigateToReportPage() {
    this.router.navigateByUrl('/pages/report');
  }
  navigateToBulkNewPage() {
    this.router.navigateByUrl('/pages/products');
    //  this._dataService.showMessage("error", "Bulk product upload functionality disabled by catalog team. This functionality will be comming soon.");
  }
  navigateToVerifyPage() {
    this.router.navigateByUrl('/pages/products/verify');
    //   this._dataService.showMessage("error", "Verify product functionality disabled by catalog team. This functionality will be comming soon.");

  }
  navigateToDownloadPage() {
    this.router.navigateByUrl('/pages/products/download');
  }
  navigateToBulkUpdate() {
    this.router.navigateByUrl('/pages/updateproducts');
    // this._dataService.showMessage("error", "Bulk update product functionality disabled by catalog team. This functionality will be comming soon.");

  }
  navigateToMapSuppliertoProduct() {
    this.router.navigateByUrl('/pages/updateproducts/map-supplier-product');
  }
  navigateToProductactiveStatus() {
    //  this.router.navigateByUrl('/pages/updateproducts/activestatus');
    this._dataService.showMessage("error", "Bulk product active status functionality disabled by catalog team. This functionality will be comming soon.");

  }
  navigateToCreateBrand() {
    this.router.navigateByUrl('/pages/brand/create');
  }
  navigateToViewBrand() {
    this.router.navigateByUrl('/pages/brand');
  }
  navigateToCreateCategory() {
    this.router.navigateByUrl('/pages/category/create');
    // this._dataService.showMessage("error", "Category functionality disabled by catalog team. This functionality will be comming soon.");
  }
  navigateToUpdateCategory() {
    this.router.navigateByUrl('/pages/category/update');
    //  this._dataService.showMessage("error", "Update category functionality disabled by catalog team. This functionality will be comming soon.");
  }
  navigateToSupplierMapping() {
    this.router.navigateByUrl('/pages/supplier/mapping');
  }
  navigateToTemplate() {
    this._state.templateRefreshEvent(true);
    this.router.navigateByUrl('/pages/template');
  }

  navigateToBulkProductOperation() {
    this.router.navigateByUrl('/pages/products');
  }

  navigateToOfferPriceMapping() {
    this.router.navigateByUrl('/pages/offer-price');
  }

  navigateToUsers() {
    this.router.navigateByUrl('/pages/users');
  }

  navigateToSupplierDownload() {
    this.router.navigateByUrl('/pages/supplier/download');
  }

}
